/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import DeleteConfirmModalState from './modal/DeleteConfirmModalState'
import { useEffect } from 'react'

const FormModal = ({
  open,
  setOpen,
  Form,
  additionalFormData = {},
  editingItem,
  setEditingItem,
  deleteAction,
  testId,
  defaultState = 'form',
  deleteOnlyModal = false,
  onModalClose,
  size = 'lg',
  onAfterClose = () => {},
  ...props
}) => {
  const [state, setState] = useState(defaultState) // "form" or "delete"

  const onClose = () => {
    if (!deleteOnlyModal) setState('form')
    setEditingItem && setEditingItem(null)
    setOpen(false)
    onModalClose?.()
  }

  useEffect(() => {
    if (!open) onAfterClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const dialogWidthClass =
    state === 'delete'
      ? 'sm:max-w-sm'
      : size === 'lg'
      ? 'sm:max-w-4xl'
      : size === 'slg'
      ? 'sm:max-w-ppt-form'
      : size === 'md'
      ? 'sm:max-w-2xl'
      : 'sm:max-w-xl'

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" data-testid={testId}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${dialogWidthClass} w-full inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6`}
            >
              {state === 'form' && (
                <Form 
                  setOpen={setOpen}
                  testId={testId ? `form-${testId}` : undefined}
                  setState={setState}
                  editingItem={editingItem}
                  setEditingItem={setEditingItem}
                  { ...additionalFormData }
                  { ...props }
                />
              )}
              {state === 'delete' && (
                <DeleteConfirmModalState
                  title={editingItem.title || editingItem.name || editingItem.fullName}
                  setState={setState}
                  testId={testId ? `delete-${testId}` : undefined}
                  deleteAction={() => {
                    deleteAction()
                    onClose()
                  }}
                  deleteOnlyModal={deleteOnlyModal}
                  onClose={onClose}
                />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default FormModal
