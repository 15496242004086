import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from './components/security/PrivateRoute'
import MoveTheNeedle from './features/move-the-needle/MoveTheNeedle'
import SignUp from './features/auth/SignUp'
import SignIn from './features/auth/SignIn'
import ForgotPassword from './features/auth/ForgotPassword'
import ResetPassword from './features/auth/ResetPassword'
import NoMatch from './components/NoMatch'
import Course from './features/course/Course'
import CoursesList from './features/courses-list/CourseList'
import PowerProfitTool from './features/power-profit-tool/PowerProfitTool'
import ProductQualifier from './features/product-qualifier/ProductQualifier'
import Profile from './features/profile/Profile'
import Resources from './features/resource/Resources'
import { checkAuthStatusAsync } from './features/auth/authSlice'
import Dashboard from './features/dashboard/Dashboard'
import UserManagement from './features/user/UserManagement'
import { ToastContainer, Slide } from 'react-toastify'
import { readManyTagsAsync } from './features/tag/tagSlice'
import Blocked from './features/stripe/Blocked'
import TagManagement from './features/tag/TagManagement'
import { ADMIN_ROLE, ALL_ROLES, COACH_ROLE, PREMIUM_ROLE } from './constants'
import DashboardTemplate from './components/DashboardTemplate'
import RoutesListener from './RoutesListener'
import useRaina from './features/search/useRaina'
import ErrorBoundary from './components/general/ErrorBoundary'
import AiToolKit from './features/tools/AIToolKit'
import Chatbase from './features/tools/Chatbase'
import ChatProductCreationExpert from './features/tools/ChatProductCreationExpert';
import { AITags } from './features/tools/const'

import CourseViewing from './features/courses-list/CourseViewing'

function App() {
  const dispatch = useDispatch()

  const { loading, currentUser } = useSelector((state) => state.auth)

  useEffect(() => {
    // automatically updates the views when a user signs in or out
    if (!currentUser) {
      dispatch(checkAuthStatusAsync())
    }
    if (currentUser) {
      dispatch(readManyTagsAsync())
    }
    // eslint-disable-next-line
  }, [currentUser])

  useRaina()

  return (
    <ErrorBoundary>
      <Router>
        <RoutesListener>
          <div className="app">
            <Switch>
              <Route path="/signin">
                {!loading && currentUser ? <Redirect to="/" /> : <SignIn />}
              </Route>
              <Route path="/signup">
                {!loading && currentUser ? <Redirect to="/" /> : <SignUp />}
              </Route>
              <Route path="/ai-tool-kit-chat">
                <Redirect to="/ai-toolkit-chat" />
              </Route>
              <Route path="/ai-tool-kit">
                <Redirect to="/ai-toolkit" />
              </Route>
              <Route path="/ai-tool-kit-chat-nova">
                <Redirect to="/ai-toolkit-chat-nova" />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/reset-password/:token">
                <ResetPassword />
              </Route>

              <DashboardTemplate>
                <Switch>
                  <PrivateRoute path="/" exact requiredRoles={['public']}>
                    {!loading && currentUser ? <Dashboard /> : <Redirect to="/signin" />}
                  </PrivateRoute>
                  <PrivateRoute path="/resources" requiredRoles={ALL_ROLES}>
                    <Resources />
                  </PrivateRoute>
                  <PrivateRoute path="/course/:moduleId/:lessonId" requiredRoles={ALL_ROLES}>
                    <Course />
                  </PrivateRoute>
                  <PrivateRoute path="/course" exact requiredRoles={ALL_ROLES}>
                  <Redirect to="/courses" />
                  </PrivateRoute>
                  <PrivateRoute path="/courses" exact requiredRoles={ALL_ROLES}>
                    <CoursesList />
                  </PrivateRoute>

                  <PrivateRoute path="/courses/:slug/:moduleId/:lessonId" requiredRoles={ALL_ROLES}>
                    <CourseViewing />
                  </PrivateRoute>
                  <PrivateRoute path="/courses/:slug/:moduleId" requiredRoles={ALL_ROLES}>
                    <CourseViewing />
                  </PrivateRoute>
                  <PrivateRoute path="/courses/:slug" requiredRoles={ALL_ROLES}>
                    <CourseViewing />
                  </PrivateRoute>

                  <PrivateRoute path="/profit-calculator" requiredRoles={ALL_ROLES}>
                    <PowerProfitTool />
                  </PrivateRoute>
                  <PrivateRoute path="/move-the-needle" requiredRoles={[COACH_ROLE, PREMIUM_ROLE]}>
                    <MoveTheNeedle />
                  </PrivateRoute>
                  <PrivateRoute path="/move-the-needle/:id" requiredRoles={[COACH_ROLE]}>
                    <MoveTheNeedle />
                  </PrivateRoute>
                  <PrivateRoute path="/product-qualifier" requiredRoles={ALL_ROLES}>
                    <ProductQualifier />
                  </PrivateRoute>
                  <PrivateRoute path="/profile" requiredRoles={ALL_ROLES}>
                    <Profile />
                  </PrivateRoute>
                  <PrivateRoute path="/users" requiredRoles={[ADMIN_ROLE, COACH_ROLE]}>
                    <UserManagement />
                  </PrivateRoute>
                  <PrivateRoute path="/tag-management" requiredRoles={[ADMIN_ROLE]}>
                    <TagManagement />
                  </PrivateRoute>
                  <PrivateRoute path="/ai-toolkit" requiredRoles={ALL_ROLES}>
                    <AiToolKit />
                  </PrivateRoute>
                  <PrivateRoute path="/ai-toolkit-chat" requiredTags={[AITags.SOPHIE]} >
                    <Chatbase />
                  </PrivateRoute>
                  <PrivateRoute path="/ai-toolkit-chat-nova" requiredTags={[AITags.NOVA]}>
                    <ChatProductCreationExpert />
                  </PrivateRoute>
                  <Route path="/blocked">
                    <Blocked />
                  </Route>
                  <PrivateRoute path="/check-stripe-payment" requiredRoles={ALL_ROLES}>
                    "checking payment status..."
                  </PrivateRoute>
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </DashboardTemplate>
            </Switch>
          </div>

          <ToastContainer
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            theme="colored"
            transition={Slide}
          />
        </RoutesListener>
      </Router>
    </ErrorBoundary>
  )
}

export default App
